import { app } from "./fire";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import axios from "axios";

const loginButton = document.querySelector(".login-button");
const provider = new GoogleAuthProvider();
const auth = getAuth(app);
auth.languageCode = "en";

loginButton.addEventListener("click", () => {
  signInWithPopup(auth, provider)
    .then((res) => {
      const user = {
        name: res.user.displayName,
        email: res.user.email,
      };
      axios({
        method: "POST",
        url: "http://localhost:8080/login",
        data: user,
      })
        .then((res) => {
          const tntech_student = {
            tn_id: res.data.tn_id,
            student_name: res.data.student_name,
            email_id: res.data.email_id,
            dob: res.data.dob,
            course_name: res.data.course_name,
            course_start: res.data.course_start,
            course_end: res.data.course_end
          };
          console.log(tntech_student);
          const html = `
            <!DOCTYPE html>
              <html>
                  <head>
                      <title>Title</title>
                      <meta charset="utf-8" />
                      <meta name="viewport" content="width=device-width, initial-scale=1" />
                      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/uikit@3.21.3/dist/css/uikit.min.css" />
                      <script src="https://cdn.jsdelivr.net/npm/uikit@3.21.3/dist/js/uikit.min.js"></script>
                      <script src="https://cdn.jsdelivr.net/npm/uikit@3.21.3/dist/js/uikit-icons.min.js"></script>
                      <style>
                        body {
                          padding: 4em;
                        }
                      </style>
                  </head>
                  <body>
                    <div class="uk-child-width-1-2@s uk-child-width-1-3@m" uk-grid="masonry: pack" >
                    <div>
                      <h4>Student Details</h4>
                        <div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 300px">
                          <ul class="uk-list">
                            
                            <li>Student ID : ${tntech_student.tn_id}</li>
                            <li>Name : ${tntech_student.student_name}</li>
                            <li>Email : ${tntech_student.email_id}</li>
                            <li>Date of Birth : ${tntech_student.dob}</li>
                            <li><a href="#" onclick="window.location.reload()">Logout</a></li>
                          </ul>
                        </div>
                      </div>
                     <div>
                      <h4>Course Details</h4>
                        <div class="uk-card uk-card-default uk-flex uk-flex-center uk-flex-middle" style="height: 300px">
                        <ul class="uk-list">
                          <li>Course Name : ${tntech_student.course_name}</li>
                          <li>Start Date : ${tntech_student.course_start}</li>
                          <li>End Date : ${tntech_student.course_end}</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </body>
              </html>
          `;
          document.title = "Telenetworks Student Dashboard";
          document.write(html);
        })
        .then((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
});
