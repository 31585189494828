import { initializeApp } from "firebase/app";

const config = {
  apiKey: "AIzaSyAtTfzBj97DCNZo4Jz0KukvDV53Yaq_Q4M",
  authDomain: "tntech-website.firebaseapp.com",
  projectId: "tntech-website",
  storageBucket: "tntech-website.appspot.com",
  messagingSenderId: "44692174880",
  appId: "1:44692174880:web:691ef64140467e6340e445",
};

const app = initializeApp(config);

export { app };
